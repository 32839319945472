import React from "react";
import YearlyChart from "./YearChart";
import MonthlyChart from "./MonthChart";
import { ApiHook } from "../../hooks/apiHook";

const CrmGraphSegment = () => {
  // --------------------------------------------- API -------------------------------------------------

  const data = ApiHook.CallCrmGraph();
  console.log(data);
  return (
    <>
      <div className="page_head_top">GRAPH</div>

      <div className="row">
        <YearlyChart />
        <MonthlyChart />
      </div>
    </>
  );
};

export default CrmGraphSegment;
