//LOCAL

// const BASE_URL = "http://192.168.10.40:5010/";
// const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff";
// const PAYPAL_CLIENT_ID =
//   "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

// // DEMO
// const BASE_URL = "http://162.19.146.135:1408/";
// const DEFAULT_KEY = '0b8d966f-13ff-461e-9496-d432445d83ff'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

//LIVE
const BASE_URL = "https://admin.frequense.com/api/";
const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff";
const PAYPAL_CLIENT_ID =
  "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";



export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };
