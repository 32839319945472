import React from "react";
import CrmTimeline from "./CrmTimeline";

const LeadsHistory = () => {
  const timelineItems = [
    {
      number: "1",
      direction: "direction-r",
      head: "introduced by company name by tester 1",
      desc: "My current employment. Way better than the position before!",
    },
    {
      number: "2",
      direction: "direction-l",
      head: "introduced by company name by tester 1",
      desc: "My first employer. All the stuff I've learned and projects I've been working on.",
    },
    {
      number: "3",
      direction: "direction-r",
      head: "introduced by company name by tester 1",
      desc: "A description of all the lectures and courses I have taken and my final degree?",
    },
  ];

  return (
    <>
      <CrmTimeline />
      <div className="crm-time-line-sec">
        <div className="time-line-form joinings_viewBox">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <ul className="timeline">
            {timelineItems.map((item, index) => (
              <li key={index}>
                <div className={item.direction}>
                  <div className="flag-wrapper">
                    <span className="flag">
                      <div className="number">
                        <p>{item.number}</p>
                      </div>
                      <div className="head">{item.head}</div>
                    </span>
                  </div>
                  <div className="desc">{item.desc}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LeadsHistory;
