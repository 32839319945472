import React from "react";
import TimelineLinks from "./crmTimelineLinks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const CrmTimeline = () => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <>
      <div className="page_head_top">
        {t("timeline")}
        <div className="right_btn_mob_toggle">
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <TimelineLinks id={params.id}/>
    </>
  );
};

export default CrmTimeline;
