import React from "react";
import CrmTimeline from "./CrmTimeline";

const LeadsDetails = () => {
  const rowData = [
    { firstName: "John Wick", loremIpsum: "Lorem Ipsum 1" },
    { firstName: "Jane Foster", loremIpsum: "Lorem Ipsum 2" },
    { firstName: "John Wick", loremIpsum: "Lorem Ipsum 1" },
    { firstName: "Jane Foster", loremIpsum: "Lorem Ipsum 2" },
  ];

  return (
    <>
      <CrmTimeline />
      <div className="crm-time-line-sec">
        <div className="time-line-form joinings_viewBox">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <table className="time-line-table">
            <tbody>
              {rowData.map((data, index) => (
                <tr key={index}>
                  <td>{data.firstName}</td>
                  <td>{data.loremIpsum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LeadsDetails;
