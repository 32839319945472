import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";

const WordPress = () => {
  const { t } = useTranslation();
  const { isLoading, isError, data } = ApiHook.CallWordPressURL();
  console.log(
    `isLoading ${isLoading} isError ${isError} data ${JSON.stringify(data)}`
  );
  if (isLoading || isError) {
    return (
      <>
        <div className="page_head_top">{t("shopping")}</div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            // position: "absolute",
            // bottom: "50%",
            // left: "50%",
            // transform: "translateX(-50%)",
          }}
        >
          <Loader
            type="spinner-circle"
            bgColor={"#ada7a7"}
            color={"#ada7a7"}
            size={500}
          />
        </div>
      </>
    );
  } else if (data.url) {
    window.location.href = data.url;
  } else {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          // position: "absolute",
          // bottom: "50%",
          // left: "50%",
          // transform: "translateX(-50%)",
        }}
      >
        <h3>Some Error Occured</h3>
      </div>
    );
  }
};

export default WordPress;
