import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const CrmService = {
    crmTiles: async () => {
        return callApi("crm-tiles");
    },
    crmGraph: async () => {
        return callApi("crm-graph");
    },
    followupToday: async (page, itemsPerPage) => {
        return callApi(`crm-followups-today?page=${page}&perPage=${itemsPerPage}`)
    },
    recentLeads: async (page, itemsPerPage) => {
        return callApi(`crm-recent-leads?page=${page}&perPage=${itemsPerPage}`)
    },
    missedFollowup: async (page, itemsPerPage) => {
        return callApi(`crm-missed-followups?page=${page}&perPage=${itemsPerPage}`)
    }
}

export default CrmService
